import React, { useState, useEffect } from "react"
import { Link } from "gatsby"
import useWindowSize from "../hooks/useGatsbyWindowSize"

import { LiveChatLoaderProvider, Intercom } from "react-live-chat-loader"

import * as indexStyles from "./index.module.css"

import SEO from "../components/seo"

import Footer from "../components/footer"
import Menu from "src/components/moreMenu"

import LogoCloud from "../components/logo-cloud"

import NickelledLogoO from "../images/svgs/nickelled-logo-o.svg"
import NickelledLogoPureWhite from "../images/svgs/nickelled-logo-o-white-full.svg"
import NickelledLogoOWhite from "../images/svgs/nickelled-logo-o-white.svg"

import StartTrialBtn from "../components/startTrialBtn"

import Header from "../components/header"
import { StaticImage } from "gatsby-plugin-image"

export default function IndexPage() {
  const [isExpanded, toggleExpansion] = useState(false)
  const [show, setShow] = useState(true)

  const { height, width } = useWindowSize()

  useEffect(() => {
    function onScroll() {
      let currentPosition = window.pageYOffset
      if (currentPosition > height) {
        setShow(false)
      } else {
        setShow(true)
      }
    }

    window.addEventListener("scroll", onScroll)
    return () => window.removeEventListener("scroll", onScroll)
  }, [show])

  var navBarLogo
  if (show) {
    navBarLogo = <NickelledLogoOWhite class="h-8 w-auto sm:h-12" />
  } else {
    navBarLogo = <NickelledLogoPureWhite class="h-8 w-auto sm:h-12" />
  }

  return (
    <>
      <Header />

      <div className="relative h-full w-full">
        <SEO
          title="Nickelled Website Tour Tool: Software rollout made easy. 100% Codeless"
          description="Product walkthrough software for everybody. The Nickelled tool makes creating website tours easy and fast. Free demo and zero coding required."
        />
        <LiveChatLoaderProvider providerKey="ii6t4rrk" provider="intercom">
          <Intercom color="#FF9C43" />
        </LiveChatLoaderProvider>

        <div
          className={
            "md:relative w-full pt-10 md:pt-32 flex flex-col md:flex-row md:items-center mb-20 md:mb-40 px-4"
          }
        >
          <section className="mx-auto max-w-7xl w-full">
            <div className="w-full md:w-1/2 mb-20 md:mb-0">
              <h1
                className={`font-bold text-2xl md:text-5xl mb-4 md:mb-8 ${indexStyles.h1}`}
              >
                Your next software rollout made slick &amp; easy
              </h1>
              <h2 className="text-lg md:text-2xl mb-4 md:mb-8">
                Get your employees up to speed fast with our digital adoption
                platform
              </h2>

              <ul
                className={`text-lg md:text-2xl mb-4 md:mb-8 ${indexStyles.benefitList}`}
              >
                <li>Employees understand new workflows faster</li>
                <li>Fewer support tickets raised during rollout</li>
                <li>Maximise ROI on software investment</li>
              </ul>

              <Link
                to="/request-demo/"
                href="https://app.nickelled.com/account/signup"
                class="hidden sm:hidden md:hidden my-4 md:my-0 lg:inline-flex items-center text-center px-6 py-4 text-xl font-semibold rounded-lg text-white bg-gradient-to-r from-oRed to-oOrange hover:opacity-90"
              >
                Book a Free Demo
              </Link>
            </div>
          </section>
          <div className="md:absolute right-0 w-full md:w-1/2 pl-10 text-right">
            <StaticImage
              src="../images/dap/step-with-abstract-browser@2x.png"
              alt="Faster adoption of new features with Nickelled"
              style={{ maxWidth: "650px" }}
              // objectFit="cover"
            />
          </div>
        </div>
      </div>

      <main className=' ${show && "sticky top-0 bg-navy"} ${!show && "relative"}'>
        <section className="mx-auto max-w-7xl mb-20 px-4">
          <h3 className="font-bold text-lg md:text-2xl text-center mb-10">
            Roll out these platforms to your entire workforce, effortlessly
          </h3>
          <ul className="grid grid-cols-4 gap-4">
            {[
              "Salesforce",
              "Zoho",
              "Pipedrive",
              "Workday",
              "Jira",
              "Microsoft Dynamics",
              "In-house",
              "Plus more...",
            ].map(platform => (
              <li
                className="rounded-lg p-4 md:p-8 text-center text-base md:text-lg"
                style={{ backgroundColor: "#FBF1EB" }}
              >
                {platform}
              </li>
            ))}
          </ul>
        </section>

        <LogoCloud></LogoCloud>

        <div class="relative bg-white pt-16 pb-32 overflow-hidden px-4">
          <div class="relative">
            <div class="lg:mx-auto lg:max-w-7xl lg:grid lg:grid-cols-2 lg:grid-flow-col-dense lg:gap-24 lg:items-center">
              <div class="px-4 max-w-xl mx-auto sm:px-6 lg:py-16 lg:max-w-none lg:mx-0 lg:px-0">
                <div>
                  <div>
                    <span class="h-12 w-12 rounded-md flex items-center justify-center bg-oOrange">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                        class="h-6 w-6 text-white"
                      >
                        <path d="M2 10.5a1.5 1.5 0 113 0v6a1.5 1.5 0 01-3 0v-6zM6 10.333v5.43a2 2 0 001.106 1.79l.05.025A4 4 0 008.943 18h5.416a2 2 0 001.962-1.608l1.2-6A2 2 0 0015.56 8H12V4a2 2 0 00-2-2 1 1 0 00-1 1v.667a4 4 0 01-.8 2.4L6.8 7.933a4 4 0 00-.8 2.4z" />
                      </svg>
                    </span>
                  </div>
                  <div class="mt-6">
                    <h2 class="text-3xl font-extrabold tracking-tight text-gray-900">
                      A Better Onboarding Experience for New Users
                    </h2>
                    <p class="mt-4 text-lg text-gray-500">
                      Launch clear, interactive click-through guides when users
                      log in for the first time. Setup is quick and doesn’t need
                      developers.
                    </p>
                    <div class="mt-6">
                      <Link
                        to="/request-demo/"
                        class="inline-flex px-4 py-2 border border-transparent text-base font-semibold rounded-md shadow-sm text-white bg-gradient-to-r from-oRed to-oOrange"
                      >
                        Book a Free Demo
                      </Link>
                    </div>
                  </div>
                </div>
                <div class="mt-8 border-t border-gray-200 pt-6">
                  <blockquote>
                    <div>
                      <p class="text-base text-gray-500">
                        &ldquo;We found Nickelled whilst looking for a solution
                        to onboard our users without the need for extensive dev
                        work - and we haven't looked back since! Perfect
                        solution, great product, and brilliant support.&rdquo;
                      </p>
                    </div>
                    <footer class="mt-3">
                      <div class="flex items-center space-x-3">
                        <div class="flex-shrink-0">
                          <StaticImage
                            src="../images/index/jessica-golding.jpg"
                            class="h-6 w-6 rounded-full"
                            alt="Jessica Golding, 90Seconds"
                          />
                        </div>
                        <div class="text-base font-medium text-gray-700">
                          Jessica Golding, Director of Customer Experience,
                          90Seconds
                        </div>
                      </div>
                    </footer>
                  </blockquote>
                </div>
              </div>
              <div class="mt-12 sm:mt-16 lg:mt-0">
                <div class="lg:pl-4">
                  <StaticImage
                    src="../images/index/bubble-new.jpg"
                    className="w-full rounded-xl shadow-xl ring-1 ring-black ring-opacity-5 w-full"
                    alt="Better onboarding with Nickelled"
                    objectPosition="0%"
                  />
                </div>
              </div>
            </div>
          </div>

          <div class="mt-24">
            <div class="lg:mx-auto lg:max-w-7xl lg:grid lg:grid-cols-2 lg:grid-flow-col-dense lg:gap-24 lg:items-center">
              <div class="px-4 max-w-xl mx-auto sm:px-6 lg:py-32 lg:max-w-none lg:mx-0 lg:px-0 lg:col-start-2">
                <div>
                  <div>
                    <span class="h-12 w-12 rounded-md flex items-center justify-center bg-oOrange">
                      <svg
                        class="h-6 w-6 text-white"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        aria-hidden="true"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M5 3v4M3 5h4M6 17v4m-2-2h4m5-16l2.286 6.857L21 12l-5.714 2.143L13 21l-2.286-6.857L5 12l5.714-2.143L13 3z"
                        />
                      </svg>
                    </span>
                  </div>
                  <div class="mt-6">
                    <h2 class="text-3xl font-extrabold tracking-tight text-gray-900">
                      Fewer Questions From Existing Users
                    </h2>
                    <p class="mt-4 text-lg text-gray-500">
                      ‘Pop’ your help content at exactly the right moment. It
                      could be when users log in for the first time, reach a
                      certain page, or take another action inside your product.
                    </p>
                    <div class="mt-6">
                      <Link
                        to="/request-demo/"
                        class="inline-flex px-4 py-2 border border-transparent text-base font-semibold rounded-md shadow-sm text-white bg-gradient-to-r from-oRed to-oOrange"
                      >
                        Book a Free Demo
                      </Link>
                    </div>
                  </div>
                </div>
                <div class="mt-8 border-t border-gray-200 pt-6">
                  <blockquote>
                    <div>
                      <p class="text-base text-gray-500">
                        &ldquo;We love the way Nickelled lets us show users
                        around our site. Now, when a customer really needs our
                        personal support, we have the time and the bandwidth to
                        help them&rdquo;
                      </p>
                    </div>
                    <footer class="mt-3">
                      <div class="flex items-center space-x-3">
                        <div class="flex-shrink-0">
                          <StaticImage
                            src="../images/index/luke-todd.png"
                            class="h-6 w-6 rounded-full"
                            alt="Luke Todd, Gumtree"
                          />
                        </div>
                        <div class="text-base font-medium text-gray-700">
                          Luke Todd, Head of Customer Ops, Gumtree
                        </div>
                      </div>
                    </footer>
                  </blockquote>
                </div>
              </div>
              <div class="mt-12 sm:mt-16 lg:mt-0 lg:col-start-1">
                <div class="lg:pr-4">
                  <StaticImage
                    src="../images/index/hotspot-new.jpg"
                    className="w-full rounded-xl shadow-xl ring-1 ring-black ring-opacity-5 w-full"
                    alt="Nickelled Hotspot in action"
                    objectFit="contain"
                  />
                </div>
              </div>
            </div>
          </div>

          <div class="mt-24">
            <div class="lg:mx-auto lg:max-w-7xl lg:grid lg:grid-cols-2 lg:grid-flow-col-dense lg:gap-24  lg:items-center">
              <div class="px-4 max-w-xl mx-auto sm:px-6 lg:py-16 lg:max-w-none lg:mx-0 lg:px-0">
                <div>
                  <div>
                    <span class="h-12 w-12 rounded-md flex items-center justify-center bg-oOrange">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                        class="h-6 w-6 text-white"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M11.3 1.046A1 1 0 0112 2v5h4a1 1 0 01.82 1.573l-7 10A1 1 0 018 18v-5H4a1 1 0 01-.82-1.573l7-10a1 1 0 011.12-.38z"
                          clip-rule="evenodd"
                        />
                      </svg>
                    </span>
                  </div>
                  <div class="mt-6">
                    <h2 class="text-3xl font-extrabold tracking-tight text-gray-900">
                      Faster Product Adoption For New Feature Launches
                    </h2>
                    <p class="mt-4 text-lg text-gray-500">
                      Showcase your newest features to the right employees, on
                      demand. Follow up with customized messaging, or link out
                      to your help docs.
                    </p>
                    <div class="mt-6">
                      <Link
                        to="/request-demo/"
                        class="inline-flex px-4 py-2 border border-transparent text-base font-semibold rounded-md shadow-sm text-white bg-gradient-to-r from-oRed to-oOrange"
                      >
                        Book a Free Demo
                      </Link>
                    </div>
                  </div>
                </div>
                <div class="mt-8 border-t border-gray-200 pt-6">
                  <blockquote>
                    <div>
                      <p class="text-base text-gray-500">
                        &ldquo;When I found Nickelled, all my prayers were
                        answered. Now, instead of explaining how to do easy
                        tasks over and over, on a one-to-one basis, I just refer
                        my employees to an intranet page where I've posted all
                        the guides.&rdquo;
                      </p>
                    </div>
                    <footer class="mt-3">
                      <div class="flex items-center space-x-3">
                        <div class="text-base font-medium text-gray-700">
                          Kasia K, Ecommerce Specialist (via Capterra)
                        </div>
                      </div>
                    </footer>
                  </blockquote>
                </div>
              </div>
              <div class="mt-12 sm:mt-16 lg:mt-0">
                <div class="lg:pl-4">
                  <StaticImage
                    src="../images/index/ha-new.jpg"
                    className="w-full rounded-xl shadow-xl ring-1 ring-black ring-opacity-5 w-full"
                    alt="Faster adoption of new features with Nickelled"
                    objectFit="cover"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="relative bg-gray-50 py-16 sm:py-24 lg:py-32 px-4">
          <div class="mx-auto max-w-md text-center sm:max-w-3xl lg:max-w-7xl">
            <h2 class="text-base font-semibold tracking-wider text-oRed uppercase">
              Nickelled Features
            </h2>
            <p class="mt-2 text-3xl font-extrabold text-gray-900 tracking-tight sm:text-4xl">
              Digital Adoption Software In Five Minutes Or Less
            </p>
            <p class="mt-5 max-w-prose mx-auto text-xl text-gray-500">
              You want your users to succeed, but you can’t always hold their
              hand.
              <br />
              Or can you?
            </p>
            <div class="mt-12">
              <div class="grid grid-cols-1 gap-8 lg:grid-cols-3">
                <div class="pt-6">
                  <div class="flow-root bg-white rounded-lg px-6 pb-8">
                    <div class="-mt-6">
                      <div>
                        <span class="inline-flex items-center justify-center p-3 bg-oOrange rounded-md shadow-lg">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                            class="h-6 w-6 text-white"
                          >
                            <path
                              fill-rule="evenodd"
                              d="M6.672 1.911a1 1 0 10-1.932.518l.259.966a1 1 0 001.932-.518l-.26-.966zM2.429 4.74a1 1 0 10-.517 1.932l.966.259a1 1 0 00.517-1.932l-.966-.26zm8.814-.569a1 1 0 00-1.415-1.414l-.707.707a1 1 0 101.415 1.415l.707-.708zm-7.071 7.072l.707-.707A1 1 0 003.465 9.12l-.708.707a1 1 0 001.415 1.415zm3.2-5.171a1 1 0 00-1.3 1.3l4 10a1 1 0 001.823.075l1.38-2.759 3.018 3.02a1 1 0 001.414-1.415l-3.019-3.02 2.76-1.379a1 1 0 00-.076-1.822l-10-4z"
                              clip-rule="evenodd"
                            />
                          </svg>
                        </span>
                      </div>
                      <h3 class="mt-8 text-lg font-medium text-gray-900 tracking-tight">
                        Guided Tours
                      </h3>
                      <p class="mt-5 text-base text-gray-500">
                        Explain features to your users with immersive on-page
                        help content.
                      </p>
                    </div>
                  </div>
                </div>

                <div class="pt-6">
                  <div class="flow-root bg-white rounded-lg px-6 pb-8">
                    <div class="-mt-6">
                      <div>
                        <span class="inline-flex items-center justify-center p-3 bg-oOrange rounded-md shadow-lg">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                            class="h-6 w-6 text-white"
                          >
                            <path
                              fill-rule="evenodd"
                              d="M3 5a2 2 0 012-2h10a2 2 0 012 2v8a2 2 0 01-2 2h-2.22l.123.489.804.804A1 1 0 0113 18H7a1 1 0 01-.707-1.707l.804-.804L7.22 15H5a2 2 0 01-2-2V5zm5.771 7H5V5h10v7H8.771z"
                              clip-rule="evenodd"
                            />
                          </svg>
                        </span>
                      </div>
                      <h3 class="mt-8 text-lg font-medium text-gray-900 tracking-tight">
                        Website Banners
                      </h3>
                      <p class="mt-5 text-base text-gray-500">
                        Draw attention to new features or announcements with a
                        sticky page banner.
                      </p>
                    </div>
                  </div>
                </div>

                <div class="pt-6">
                  <div class="flow-root bg-white rounded-lg px-6 pb-8">
                    <div class="-mt-6">
                      <div>
                        <span class="inline-flex items-center justify-center p-3 bg-oOrange rounded-md shadow-lg">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                            class="h-6 w-6 text-white"
                          >
                            <path
                              fill-rule="evenodd"
                              d="M18 10c0 3.866-3.582 7-8 7a8.841 8.841 0 01-4.083-.98L2 17l1.338-3.123C2.493 12.767 2 11.434 2 10c0-3.866 3.582-7 8-7s8 3.134 8 7zM7 9H5v2h2V9zm8 0h-2v2h2V9zM9 9h2v2H9V9z"
                              clip-rule="evenodd"
                            />
                          </svg>
                        </span>
                      </div>
                      <h3 class="mt-8 text-lg font-medium text-gray-900 tracking-tight">
                        Onsite Widgets
                      </h3>
                      <p class="mt-5 text-base text-gray-500">
                        Tee up a series of help resources and ‘pop’ them on the
                        page.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="bg-white relative px-4">
          <div class="max-w-7xl mx-auto py-16 lg:py-24 lg:grid lg:grid-cols-2 lg:gap-x-8">
            <div>
              <h2 class="text-base font-semibold text-oRed uppercase tracking-wide">
                What's Your Package?
              </h2>
              <p class="mt-2 text-3xl font-extrabold text-gray-900">
                Nickelled works on 99% of cloud-based software
              </p>
              <p class="mt-4 text-lg text-gray-500">
                Unlike other software, we’re built for compatibility. We work,
                when others won't.
              </p>
            </div>

            <div class="mt-12 lg:mt-0 lg:col-span-1">
              <dl class="space-y-10 sm:space-y-0 sm:grid sm:grid-cols-1 sm:grid-flow-row sm:gap-x-6 sm:gap-y-10 lg:gap-x-8">
                <div class="flex">
                  <svg
                    class="flex-shrink-0 h-6 w-6 text-oCyan"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M5 13l4 4L19 7"
                    />
                  </svg>
                  <div class="ml-3">
                    <dt class="text-lg leading-6 font-medium text-gray-900">
                      Cross-Platform
                    </dt>
                    <dd class="mt-2 text-base text-gray-500">
                      Works on mobile, desktop and tablet.
                    </dd>
                  </div>
                </div>

                <div class="flex">
                  <svg
                    class="flex-shrink-0 h-6 w-6 text-oCyan"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M5 13l4 4L19 7"
                    />
                  </svg>
                  <div class="ml-3">
                    <dt class="text-lg leading-6 font-medium text-gray-900">
                      Zero Dev Time
                    </dt>
                    <dd class="mt-2 text-base text-gray-500">
                      No difficult development work, create your first guide in
                      five minutes or less.
                    </dd>
                  </div>
                </div>

                <div class="flex">
                  <svg
                    class="flex-shrink-0 h-6 w-6 text-oCyan"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M5 13l4 4L19 7"
                    />
                  </svg>
                  <div class="ml-3">
                    <dt class="text-lg leading-6 font-medium text-gray-900">
                      Zero Downloads
                    </dt>
                    <dd class="mt-2 text-base text-gray-500">
                      No software downloads or browser extensions.
                    </dd>
                  </div>
                </div>

                <div class="flex">
                  <svg
                    class="flex-shrink-0 h-6 w-6 text-oCyan"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M5 13l4 4L19 7"
                    />
                  </svg>
                  <div class="ml-3">
                    <dt class="text-lg leading-6 font-medium text-gray-900">
                      Optional Javascript
                    </dt>
                    <dd class="mt-2 text-base text-gray-500">
                      Javascript is optional but encouraged. If you can't add
                      Javascript, no problem.
                    </dd>
                  </div>
                </div>

                <div class="flex">
                  <svg
                    class="flex-shrink-0 h-6 w-6 text-oCyan"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M5 13l4 4L19 7"
                    />
                  </svg>
                  <div class="ml-3">
                    <dt class="text-lg leading-6 font-medium text-gray-900">
                      World-Class Support
                    </dt>
                    <dd class="mt-2 text-base text-gray-500">
                      We respond to most queries within one hour.
                    </dd>
                  </div>
                </div>

                <div class="flex">
                  <svg
                    class="flex-shrink-0 h-6 w-6 text-oCyan"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M5 13l4 4L19 7"
                    />
                  </svg>
                  <div class="ml-3">
                    <dt class="text-lg leading-6 font-medium text-gray-900">
                      Easy Billing
                    </dt>
                    <dd class="mt-2 text-base text-gray-500">
                      Pay via credit card or annual invoice.
                    </dd>
                  </div>
                </div>
              </dl>
            </div>
          </div>
        </div>

        <section class="py-12 bg-gray-50 overflow-hidden md:py-20 lg:py-24 relative px-4">
          <div class="relative max-w-7xl mx-auto">
            <svg
              class="absolute top-full right-full transform translate-x-1/3 -translate-y-1/4 lg:translate-x-1/2 xl:-translate-y-1/2"
              width="404"
              height="404"
              fill="none"
              viewBox="0 0 404 404"
              role="img"
              aria-labelledby=""
            >
              <title id=""></title>
              <defs>
                <pattern
                  id="ad119f34-7694-4c31-947f-5c9d249b21f3"
                  x="0"
                  y="0"
                  width="20"
                  height="20"
                  patternUnits="userSpaceOnUse"
                >
                  <rect
                    x="0"
                    y="0"
                    width="4"
                    height="4"
                    class="text-gray-200"
                    fill="currentColor"
                  />
                </pattern>
              </defs>
              <rect
                width="404"
                height="404"
                fill="url(#ad119f34-7694-4c31-947f-5c9d249b21f3)"
              />
            </svg>

            <div class="relative">
              <StaticImage
                src="../images/index/gengo-logo.png"
                class="mx-auto h-8 w-full"
                alt="Gengo Logo"
                objectFit="contain"
              />
              <blockquote class="mt-10">
                <div class="max-w-3xl mx-auto text-center text-2xl leading-9 font-medium text-gray-900">
                  <p>
                    &ldquo;Nickelled was the only company that we found where we
                    could create tutorials to our liking without needing to add
                    HTML code to our website.&rdquo;
                  </p>
                </div>
                <footer class="mt-8">
                  <div class="md:flex md:items-center md:justify-center">
                    <div class="hidden md:flex md:flex-shrink-0">
                      <StaticImage
                        src="../images/index/lara-fernandez.png"
                        class="mx-auto h-10 w-10 rounded-full"
                        alt="Lara Fernandez, Community Manager Gengo"
                      />
                    </div>
                    <div class="mt-3 text-center md:mt-0 md:ml-4 md:flex md:items-center">
                      <div class="text-base font-medium text-gray-900">
                        Lara Fernandez
                      </div>
                      <svg
                        class="hidden md:block mx-1 h-5 w-5 text-oOrange"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                      >
                        <path d="M11 0h3L9 20H6l5-20z" />
                      </svg>

                      <div class="text-base font-medium text-gray-500">
                        Community Manager, Gengo
                      </div>
                    </div>
                  </div>
                </footer>
              </blockquote>
            </div>
          </div>
        </section>
      </main>
      <Footer></Footer>
    </>
  )
}
